import React, { useEffect } from "react";
import banner from "../assets/desk.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Desk = () => {

  useEffect(() => {
    AOS.refresh(); // Refresh AOS when the component mounts
  }, []);

  return (
    <div className="mt-4 mb-20 sm:mt-16">
      <div className="about-section flex flex-col sm:flex-row items-start justify-center gap-8 px-4 sm:px-[6.5rem]">
        <div data-aos="fade-right" data-aos-duration="1000" className="about-text">
        <h3 className="font-bold text-3xl sm:text-4xl pb-4 text-[#255087]">
            Headmistress' Desk
        </h3>
          <h3 className="font-bold text-xl sm:text-2xl pb-2 text-gray-600">
          For a command is a lamp, teaching  is a light and corrective discipline is the way of life.
          </h3>
          <p className="text-gray-600 text-sm sm:text-base">
            Education is not just a process a giving knowledge for a future job
            but a life long process which creates an understanding of moral and
            ethical values to guide one's life and make our children the future
            hope of the country. St. James Church School where the discipline is
            a way life emphasize on child's holistic development nurturing young
            minds to take to challenges in academics, in life and also focus on
            providing quality, value based education. OUR MOTTO IS "QUEST FOR
            EXCELLENCE" Our institutional challenge is “to be better tomorrow
            than we are today’’! <br/> Admissions are open to all.
          </p>
          <p className="text-right font-bold"> - Yogita Prasad, Headmistress</p>
          <div className="line h-[2px] w-full bg-[#cdb13f] mt-5"></div>
        </div>
        <img data-aos="fade-left" data-aos-duration="1000" alt="sample" src={banner} className="w-screen sm:w-[40rem]" />
      </div>
    </div>
  );
};

export default Desk;
