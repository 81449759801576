'use client'

import React, { useState } from 'react'
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../assets/logo.jpg"
import { IoCloseOutline } from 'react-icons/io5';


const Navbar = () => {

    const [showBurger, setShowBurger] = useState(false);

    const scrollToSection = (sectionId) => {
      document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
      // If you are using Next.js 11+, you can use the following router method instead:
      //router.push(`#${sectionId}`, undefined, { scroll: false });
    };

  return (
    <nav className='w-full'>
        <div className='w-full flex items-center justify-between py-2 px-4 sm:px-10 sm:py-3 z-[999]'>
            <div className="logo flex items-center justify-start gap-4">
                <img alt="sample" src={logo} height={50} width={55} />
                <h2 className='font-bold leading-5 text-[#255087] text-xl sm:text-2xl w-40 sm:w-full'>St. James Church School, Etah</h2>
            </div>
            <div className="right">
                <div className='items-center justify-center gap-12 hidden md:flex'>
                  <a className='cursor-pointer hover:border-b-2 border-[#255087]'>Home</a>
                  <a className='cursor-pointer hover:border-b-2 border-[#255087]' onClick={() => scrollToSection('facilities')}>Facilities</a>
                  <a className='cursor-pointer hover:border-b-2 border-[#255087]' onClick={() => scrollToSection('about')}>About</a>
                  <a className='cursor-pointer hover:border-b-2 border-[#255087]' onClick={() => scrollToSection('office')}>Office</a>
                  <a className='cursor-pointer hover:border-b-2 border-[#255087]' onClick={() => scrollToSection('contact')}>Contact</a>
                </div>
                <RxHamburgerMenu className='text-[28px] cursor-pointer sm:hidden' onClick={()=> setShowBurger(true)}/>
                {showBurger && (
                <div className={`flex items-center justify-start gap-7 flex-col w-[200px] h-[20rem] rounded-[7px] pt-10 absolute top-[4.5rem] ease-in-out bg-[#255087] z-50 ${showBurger ? 'right-[0rem]' : '-right-5'}`}>
                  <a className='text-white text-md cursor-pointer'>Home</a>
                  <a onClick={() => scrollToSection('facilities')} className='text-white text-md cursor-pointer'>Facilities</a>
                  <a onClick={() => scrollToSection('about')} className='text-white text-md cursor-pointer'>About</a>
                  <a onClick={() => scrollToSection('office')} className='text-white text-md cursor-pointer'>Office</a>
                  <a onClick={() => scrollToSection('contact')} className='text-white text-md cursor-pointer'>Contact</a>
                  <IoCloseOutline className='absolute top-1 right-2 text-2xl text-white cursor-pointer z-[10000]' onClick={() => setShowBurger(false)}/>
                </div>
                )}
            </div>
        </div>
        <div className="notice bg-[#facc15] text-base text-gray-700 font-semibold px-2 py-1">
        <p className='notice-text whitespace-nowrap'>Admissions Open | Session 2024-2025 | Office Hours: 8AM - 2PM | Visit School Now to Register</p>
      </div>
    </nav>
    
  )
}

export default Navbar