import React from 'react'
import banner from "../assets/banner.jpg"

const Banner = () => {
  return (
    <div className='relative z-[-100]'>
        <img alt="sample" src={banner} className='w-screen h-[34rem] object-cover brightness-75 z-0' />
        <div className="text-container flex items-center justify-center">
        <div className="main-text absolute top-80 w-80 sm:left-[5rem] sm:w-[40%]">
            <h1 className='text-white font-bold text-4xl'>Faith, Community and Excellence</h1>
            <p className='text-gray-200 text-md'>We never forget our roots, we just grow new branches!</p>
        </div>
        </div>
        <div className="line w-[4px] h-20 bg-yellow-400 ml-16 z-[100] -mt-3"></div>
    </div>
  )
}

export default Banner