import React, { useEffect } from "react";
import banner from "../assets/about-img.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';


const About = () => {

  useEffect(() => {
    AOS.refresh(); // Refresh AOS when the component mounts
  }, []);

  return (
    <div className="mt-28 mb-20">
      
      <div className="about-section flex flex-col sm:flex-row items-start justify-center gap-8 px-4 sm:px-[6.5rem]">
        <img data-aos="fade-right" data-aos-duration="1000" alt="sample" src={banner} className="w-screen sm:w-[40rem]" />
        <div data-aos="fade-left" data-aos-duration="1000" className="about-text">
        <h3 className="font-bold text-3xl sm:text-4xl pb-4 text-[#255087]">
            About
        </h3>
          <h3 className="font-bold text-xl sm:text-2xl text-gray-600">
            Foundation and Vision
          </h3>
          <p className="text-gray-600 text-sm sm:text-base">
            St. James Church School was founded in 16th July 2018. (St. James
            Church School ) is an unaided , co-educational and an English Medium
            Christian School. It operates under the direction and control of the
            Diocese of Agra (Church of North India ). St.James being a christian
            schools seeks to encourage , a good character and responsible
            citizenship . The school follows the syllabus of the central board
            of secondary education (CBSE), New Delhi
          </p>
          <div className="line h-[2px] w-full bg-[#cdb13f] mt-5"></div>
        </div>
      </div>
    </div>
  );
};

export default About;
