
import './App.css';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Gallery from './components/Gallery';
import About from './components/About';
import Desk from './components/Desk';
import Events from './components/Events';
import Contact from './components/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the styles

function App() {

  AOS.init(); 

  return (
    <main className="w-full flex items-center flex-col overflow-x-hidden">
      <Navbar />
      <Banner />

      <section id="facilities">
        <Gallery />
      </section>

      <section id="about">
       <About />
      </section>
      
      <section id="office">
        <Desk />
      </section>
      
      <Events />

      <section id="contact">
        <Contact />
      </section>
    </main>
  )
}

export default App;
