'use client'

import React, { useEffect } from "react";
import img1 from "../assets/bus.jpg";
import img2 from "../assets/computer-lab.jpg";
import img3 from "../assets/library.jpg";
import { IoChevronForward } from "react-icons/io5";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Gallery = () => {

  useEffect(() => {
    AOS.refresh(); // Refresh AOS when the component mounts
  }, []);

  return (
    <div className="flex items-center justify-center flex-col sm:flex-row gap-2 sm:gap-10 m-1 mt-5">
      <div className="grid1 flex items-center justify-center gap-2 sm:gap-10">
        <div data-aos="fade-right">
          <img alt="sample" src={img1} className="w-44 h-28 sm:h-[10.9rem] object-cover sm:w-[26rem]" />
          <div className="feature-text flex items-center justify-start gap-2 mt-2">
            <p className="text-blue-900 font-semibold">Bus</p>
            <IoChevronForward className="text-yellow-500"/>
          </div>
        </div>
        <div>
          {/* <img alt="sample" src={img2} className="w-44 h-28 object-cover sm:h-[10.9rem] sm:w-96" /> */}
          <img alt="sample" src={img3} className="w-44 h-28 object-cover sm:h-[10.9rem] sm:w-[26rem]" />
          <div className="feature-text flex items-center justify-start gap-2 mt-2">
            <p className="text-blue-900 font-semibold">Library</p>
            <IoChevronForward className="text-yellow-500"/>
          </div>
        </div>
      </div>
      <div className="grid-2">
      <div data-aos="fade-left">
          <img alt="sample" src={img2} className="w-[22.4rem] h-44 sm:h-[10.9rem] sm:w-[26rem] object-cover" />
          <div className="feature-text flex items-center justify-start gap-2 mt-2">
            <p className="text-blue-900 font-semibold">Computer Lab</p>
            <IoChevronForward className="text-yellow-500"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
