'use client'

import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import img1 from "../assets/img-1.jpg";
import img2 from "../assets/img-2.jpg";
import img3 from "../assets/img-3.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img-5.jpg";
import img6 from "../assets/img-6.jpg";
import img7 from "../assets/img-7.jpg";
import img8 from "../assets/img-8.jpg";
import img9 from "../assets/img-9.jpg";


const Events = () => {
  return (
    <div className="px-24 py-5">
      <div>
        <h1 className="text-3xl sm:text-4xl font-bold mb-10 text-center text-[#255087]">Events</h1>
      </div>
      <div className="carousel px-3 sm:px-2">
        <Carousel autoPlay interval={1000} className="w-72 sm:w-screen sm:px-20">
          <div className="flex items-center justify-center gap-6">
            <img alt="sample" src={img1} className="h-56 w-[22rem] object-cover" />
            <img alt="sample" src={img2} className="h-56 w-[22rem] object-cover" />
            <img alt="sample" src={img3} className="h-56 w-[22rem] object-cover" />
          </div>
          <div className="flex items-center justify-center gap-6">
            <img alt="sample" src={img4} className="h-56 w-[22rem] object-cover" />
            <img alt="sample" src={img5} className="h-56 w-[22rem] object-cover" />
            <img alt="sample" src={img6} className="h-56 w-[22rem] object-cover" />
          </div>
          <div className="flex items-center justify-center gap-6">
            <img alt="sample" src={img7} className="h-56 w-[22rem] object-cover" />
            <img alt="sample" src={img8} className="h-56 w-[22rem] object-cover" />
            <img alt="sample" src={img9} className="h-56 w-[22rem] object-cover" />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Events;
