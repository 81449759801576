import React from "react";
import logo from "../assets/logo-bgr.png"
import logo2 from "../assets/dol.png"
import { FaPhoneAlt } from "react-icons/fa";
import { FaSchool } from "react-icons/fa";
import { MdEmail } from "react-icons/md";


const Contact = () => {

    const enqType = ['Admission Enquiry', 'Job Enquiry']

  return (
    <div>
      <h1 className="text-3xl sm:text-4xl font-bold mb-10 mt-5 text-center text-[#255087]">Contact Us</h1>
      <div className="location-section flex flex-col sm:flex-row items-center justify-center gap-10 sm:gap-40 w-screen py-10">
        <div className="map">
          <iframe className="w-[21.5rem] sm:w-[30rem]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3537.378824731801!2d78.64042605417613!3d27.55075124794084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3974ff0d81329e4d%3A0x89715b299ad9e8bb!2sSt.%20James%20Church%20School!5e0!3m2!1sen!2sin!4v1706973983944!5m2!1sen!2sin"
            width="500"
            height="450"
            style={{ border: "0", borderRadius: "7px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <form className="contact-form flex flex-col items-start justify-start bg-[#d3deff] p-10 gap-4 h-[28.2rem] w-[22rem] sm:w-[29rem] rounded-[5px]">
            <h2 className="font-bold text-2xl text-blue-800">Enquire</h2>
          <div className="flex flex-col items-start justify-center">
            <label className="pb-1">Full Name</label>
            <input type="text" placeholder="Enter Name" className="px-2 py-2 rounded-[4px] w-[17rem] sm:w-96" />
          </div>
          <div className="flex flex-col items-start justify-center">
            <label className="pb-1">Phone Number</label>
            <input type="text" placeholder="Enter Name" className="px-2 py-2 rounded-[4px] w-[17rem] sm:w-96" />
          </div>
          <div className="flex flex-col items-start justify-center">
            <label className="pb-1">Enquiry Type</label>
            <select className="px-2 py-2 rounded-[4px] w-[17rem] sm:w-96">
              {enqType.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col items-start justify-center w-full">
            <button type="submit" className="w-full bg-[#255087] text-white py-2 mt-6 rounded-[4px]">Submit</button>
          </div>
        </form>
      </div>
      <div className="spacer h-[1px] w-screen bg-gray-400"></div>
      <footer className="w-full flex flex-col sm:flex-row items-start sm:items-center justify-between p-14 text-gray-200 bg-[#255087]">

        <div className="left flex items-center justify-start gap-4">
          <div className="logo">
          <img alt="sample" src={logo} className="border-2 rounded-full p-[3px] bg-white w-16" />
          </div>
          <div className="nameAddress">
            <h2 className="text-sm sm:text-base">St. James Church School, <br /> Etah</h2>
          </div>
        </div>

        <div className="middle flex flex-col items-start justify-center gap-1 sm:gap-0 mt-5">
          <div className="address flex items-start sm:items-center justify-start gap-2">
            <FaSchool className="text-lg sm:text-base"/>
            <p className="text-sm sm:text-base">Agra road, Etah</p>
          </div>
          <div className="email flex items-center justify-start gap-2">
            <MdEmail />
            <p className="text-sm sm:text-base">st.jamesschool24@gmail.com</p>
          </div>
          <div className="contact flex items-center justify-start gap-2">
            <FaPhoneAlt />
            <p className="text-sm sm:text-base">+91 8979427622</p>
          </div>
        </div>

        <div className="right">
          <div className="logo2 mt-5 sm:m-0 flex items-center justify-start gap-4">
            <img alt="sample" src={logo2} className="rounded-full w-16" />
            <h2 className="text-sm sm:text-base">Diocese of Agra, CNI</h2>
          </div>
        </div>

        {/* <p className="text-black text-sm">Copyright ⓒ St. James Church School, Etah</p>
        <p className="text-black text-sm">st.jamesschool24@gmail.com</p>
        <p className="text-black text-sm">+91 8979427622</p> */}
      </footer>
      <div className="spacer w-screen h-[0.7px] bg-gray-400"></div>
      <div className="copyright bg-[#255087] flex items-center justify-center flex-col pt-5 pb-2 sm:pb-4">
        <p className="text-gray-200 text-xs sm:textsm">Copyright ⓒ St. James Church School, Etah</p>
      </div>
    </div>
  );
};

export default Contact;
